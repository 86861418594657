import React, { useState } from 'react';
import { ArticleCard, Text } from '@qga/roo-ui/components';
import ArticleGallery from '../ArticleGallery';
import ArticleBody from '../ArticleBody';
import ArticleFooter from '../ArticleFooter';
import ArticleAnnotation from '../ArticleAnnotation';
import PropTypes from 'prop-types';
import Tile from '@/propTypes/Campaign';
import { useCustomerSelection } from '@/components/CustomerSelectionProvider';
import bundleBuilder from '@/components/ExclusiveOffersPage/components/ArticleFooter/utils/bundleBuilder';
import AllPackageOptionsModal from '@/v2/components/ui/AllPackageOptionsModal';
import getPackageOptions from '@experiences-ui/shared/v2/utils/getPackageOptions';
import { useFeatureFlag } from '@/libs/v2/contexts/featureFlags';
import useSearchQuery from '@/libs/v2/hooks/useSearchQuery';
import getPrimaryOriginCode from '@/utils/getPrimaryOriginCode';
import { usePackagePageV2Analytics } from '@/v2/hooks/featureFlags/packagePageV2Analytics/usePackagePageV2Analytics';
import { useDataLayerContext } from '@experiences-ui/shared/v2/contexts/DataLayerContext';
import { getPackageOptionTitle } from '@/v2/utils/packageOption';
import StarRating from '@/libs/v2/components/StarRating';
import useSelectItemEvent from '@/v2/hooks/useSelectItemEvent';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';
import { getLeadInPriceFromCampaignTile } from '@/utils/getLeadInPriceFromProcuredOffer';
import getHotelsLeadInPrice from '@/utils/getHotelsLeadInPrice';

const ProcuredOfferOption = ({ tile, index }) => {
  const { procuredOffer } = tile;
  const { rooms } = procuredOffer;
  const { searchQuery } = useSearchQuery();
  const originCode =
    searchQuery?.originCode || getPrimaryOriginCode(procuredOffer?.origins);
  const isNewPackagePageEnabled =
    useFeatureFlag('package-page')?.treatment === 'on' || false;
  const shouldUseNewAnalytics = usePackagePageV2Analytics();
  const { pageName } = useDataLayerContext() || {};
  const shouldUseNewEvents = useGA4Events();

  const { packageOptions } = getPackageOptions({
    procuredOffer,
    originCode,
    minNumberOfNights: rooms[0].leadInOffers[0].minNumberOfNights,
    commonInclusions: undefined,
    title: getPackageOptionTitle(procuredOffer),
  });

  const [showAllOptionsModal, setShowAllOptionsModal] = useState(false);
  const { usePoints } = useCustomerSelection();

  const packageOptionId =
    isNewPackagePageEnabled &&
    packageOptions.length > 0 &&
    packageOptions[0].id;

  const hasMultiplePackageOption =
    isNewPackagePageEnabled && packageOptions.length > 1;

  const destination = tile?.propertyContent?.destination?.title;

  const selectItemEventData = useSelectItemEvent({
    originCode,
    property: tile,
    destination,
    name:
      pageName === 'Home page'
        ? 'Discover our latest Luxury Holiday offers'
        : 'Qantas Luxury Holidays',
    price:
      getHotelsLeadInPrice(tile.procuredOffer?.rooms[0].leadInOffers[0])?.total
        ?.amount ||
      getLeadInPriceFromCampaignTile(tile, searchQuery?.originCode)?.total
        ?.amount,
    index,
  });

  const { bundles, bundleAndSaveExists, hasMultipleCompleteBundles } =
    bundleBuilder(tile, {
      originCode,
      usePoints,
      setShowAllOptionsModal,
      isNewPackagePageEnabled,
      packageOptionId,
      hasMultiplePackageOption,
      useNewAnalytics: shouldUseNewAnalytics,
      pageName,
      selectItemEventData: shouldUseNewEvents ? selectItemEventData : null,
    });

  const destinationTitle = tile.propertyContent?.destination?.title;
  const tileName = tile.name;
  const id = tile.propertyContent.id;
  const offerTitle = tile.procuredOffer?.description;
  const tagNames = (tile.propertyContent?.tags ?? [])
    .slice(0, 2)
    .map(({ name }) => name);

  const starRatingProps = {
    size: 14,
    rating: tile.rating,
    ratingType: tile.ratingType,
  };

  return (
    <>
      <ArticleCard
        badge={destinationTitle}
        title={tileName}
        id={`card-${id}`}
        description={offerTitle}
        tags={tagNames}
        starRatingProps={starRatingProps}
        StarRatingComponent={StarRating}
        footerCallToAction={
          hasMultipleCompleteBundles && (
            <Text fontSize="14px" fontWeight="bold">
              CHOOSE AN OPTION:
            </Text>
          )
        }
        footerComponent={
          <ArticleFooter
            bundles={bundles}
            bundleAndSaveExists={bundleAndSaveExists}
          />
        }
        imageComponent={<ArticleGallery tile={tile} />}
        bodyComponent={<ArticleBody tile={tile} />}
        annotation={<ArticleAnnotation tile={tile} />}
      />
      {showAllOptionsModal && (
        <AllPackageOptionsModal
          onClose={() => setShowAllOptionsModal(false)}
          tile={tile}
          packageOptions={packageOptions}
        />
      )}
    </>
  );
};

ProcuredOfferOption.propTypes = {
  tile: PropTypes.shape(Tile).isRequired,
  index: PropTypes.number.isRequired,
};

export default ProcuredOfferOption;
